/* eslint-disable class-methods-use-this */
import http from '@/http';
import Service from './Service';

class ClientService extends Service {
  constructor() {
    super('clients');
  }

  getAgentClients(id) {
    return http.get(`/get-agent-clients/${id}`);
  }

  getFieldsTableClients() {
    return [
      {
        key: 'name',
        label: 'Nome',
      },
      {
        key: 'street',
        label: 'Rua',
      },
      {
        key: 'neighborhood',
        label: 'Bairro',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'city',
        label: 'Cidade',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'province',
        label: 'Estado',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'actions',
        label: 'Ações',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
    ];
  }
}

const clientService = new ClientService();
export default clientService;
